import React, { FunctionComponent } from 'react'

import { Box, Flex } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { baseColors, Text } from '@fairhq/common'
import { WhiteBoxWithShadow } from 'components/WhiteCardWithShadow'
import { useOpenable } from 'hooks/useOpenable'

import { useGetCategoryInsightsQuery } from 'store/category/categoryApi'
import { SubCategoryExperience } from 'store/category/types'

import Icon from 'ui-kit/Icon'

import { ExperienceBar } from './ExperienceBar'

const Accordion = styled(WhiteBoxWithShadow)`
  flex-direction: column;
`

const List = styled.ul`
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 0;
`

const ListItem = styled.li`
  &:not(:last-of-type) {
    padding-bottom: 8px;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  cursor: pointer;
  &:focus-visible {
    outline: 2px solid ${baseColors.purple700};
    outline-offset: 2px;
    border-radius: 8px;
  }
`

interface CategoryAccordionProps {
  categoryExperience: SubCategoryExperience
  previousExperience?: SubCategoryExperience
}

export const CategoryAccordion: FunctionComponent<CategoryAccordionProps> = ({
  categoryExperience,
  previousExperience,
}) => {
  const { t } = useTranslation()
  const { opened, toggle } = useOpenable()

  const { data } = useGetCategoryInsightsQuery({
    category: categoryExperience.subCategory,
  })

  const recommendations = data?.flatMap(insight => insight.recommendations)

  return (
    <Accordion>
      <Flex
        role={data && data?.length > 0 ? 'button' : 'div'}
        onClick={toggle}
        sx={{
          width: '100%',
          p: '24px 40px',
          alignItems: 'center',
          justifyContent: 'space-between',
          userSelect: 'none',
          cursor: data && data?.length > 0 ? 'pointer' : 'cursor',
          gap: '60px',
        }}
      >
        <Flex
          sx={{
            flexGrow: 1,
            alignItems: 'center',
          }}
        >
          <Text
            element="p"
            type="body"
            size="lg"
            fontWeight={700}
            color={baseColors.grey500}
          >
            {t(`category.${categoryExperience.subCategory}`)}
          </Text>
          {data && data?.length > 0 ? (
            <Icon
              name={opened ? 'arrowDown' : 'arrowRight'}
              sx={{ ml: 2, flexShrink: 0 }}
              small
            />
          ) : null}
        </Flex>

        <ExperienceBar
          categoryExperience={categoryExperience}
          previousExperience={previousExperience}
        />
      </Flex>

      {opened && recommendations && recommendations?.length > 0 && (
        <Box
          sx={{
            py: 6,
            px: 8,
            backgroundColor: 'grey100',
          }}
        >
          <Text element="p" type="body" size="md" fontWeight={400}>
            Sample initiatives:
          </Text>
          <List>
            {recommendations.map(rec => (
              <ListItem>
                <StyledLink
                  aria-label={`Open the guide: ${rec.description}`}
                  to={`/strategy/recommendation/${rec.code}`}
                  key={rec.id}
                >
                  {rec.description}
                </StyledLink>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Accordion>
  )
}
