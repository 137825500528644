import { baseColors } from '@fairhq/common'
import { SubCategoryExperience } from 'store/category/types'

export const useExperienceBar = ({
  categoryExperience,
  previousExperience,
}: {
  categoryExperience: SubCategoryExperience
  previousExperience?: SubCategoryExperience
}) => {
  const { count, detractors, passives, promoters } = categoryExperience

  const totalBy100 = count / 100
  const pecentages = {
    total: count,
    detractors: detractors / totalBy100,
    passives: passives / totalBy100,
    promoters: promoters / totalBy100,
  }

  const colors = {
    green: baseColors.fairGreen400,
    yellow: baseColors.yellow,
    red: baseColors.red400,
  }

  const backgroundImage = `linear-gradient(90deg, ${colors.red} ${
    pecentages.detractors
  }%, ${colors.yellow} ${pecentages.detractors}%, ${colors.yellow} ${
    pecentages.detractors + pecentages.passives
  }%, ${colors.green} ${pecentages.detractors + pecentages.passives}%)`

  const currentExperience = {
    sadSize:
      pecentages.detractors > pecentages.promoters &&
      pecentages.detractors > pecentages.passives
        ? '24'
        : '12',
    happySize:
      pecentages.promoters > pecentages.detractors &&
      pecentages.promoters > pecentages.passives
        ? '24'
        : '12',
    backgroundImage,
    colors,
    enps: Math.round(pecentages.promoters - pecentages.detractors).toFixed(0),
    enpsDiff: undefined,
  }

  if (!previousExperience) {
    return currentExperience
  }

  // previous
  const totalBy100Previous = previousExperience.count / 100
  const pecentagesPrevious = {
    total: count,
    detractors: previousExperience.detractors / totalBy100Previous,
    passives: previousExperience.passives / totalBy100Previous,
    promoters: previousExperience.promoters / totalBy100Previous,
  }

  return {
    ...currentExperience,
    enpsDiff: Math.round(
      Number(currentExperience.enps) -
        (pecentagesPrevious.promoters - pecentagesPrevious.detractors)
    ).toFixed(1),
  }
}
