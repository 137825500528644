import React, { FunctionComponent } from 'react'

import { useParams } from 'react-router'
import styled from 'styled-components'

import { baseColors, Spinner, Text } from '@fairhq/common'
import { useGetCategoriesExperienceQuery } from 'store/category/categoryApi'

import { SubCategoryExperience } from 'store/category/types'

import { CategoryAccordion } from './CategoryAccordion'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ColumnHeader = styled(Text)`
  align-self: self-end;
  min-width: 274px;
`

interface CategoriesProps {
  loading: boolean
  previousSurveyWindowId?: number
  categoryExperiences?: SubCategoryExperience[]
}

export const Categories: FunctionComponent<CategoriesProps> = ({
  loading,
  previousSurveyWindowId,
  categoryExperiences,
}) => {
  const { category } = useParams<{ category: string }>()

  const { data: previousExperienceCategory } = useGetCategoriesExperienceQuery(
    { category, surveyWindowId: previousSurveyWindowId },
    { skip: !category }
  )

  return (
    <Container>
      {loading && <Spinner center />}

      <ColumnHeader
        element="p"
        type="body"
        size="sm"
        fontWeight={700}
        color={baseColors.grey400}
      >
        EMPLOYEE EXPERIENCE
      </ColumnHeader>
      {categoryExperiences &&
        categoryExperiences?.length > 0 &&
        categoryExperiences.map(item => (
          <CategoryAccordion
            key={item.subCategory}
            categoryExperience={item}
            previousExperience={previousExperienceCategory?.find(
              previous => previous.subCategory === item.subCategory
            )}
          />
        ))}
    </Container>
  )
}
