import queryString from 'query-string'

import { emptySplitApi } from 'emptySplitApi'

import { Insight } from 'features/insights/types'

import { SubCategoryExperience } from './types'

const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getCategories: build.query<string[], void>({
      query: () => ({
        url: `categories`,
      }),
    }),
    getCategoriesExperience: build.query<
      SubCategoryExperience[],
      { category?: string; surveyWindowId?: number }
    >({
      query: ({ category, surveyWindowId }) => ({
        url: `categories/${category}/experience?${queryString.stringify({
          surveyWindowId,
        })}`,
      }),
    }),
    getCategoryInsights: build.query<Insight[], { category: string }>({
      query: ({ category }) => ({
        url: `categories/${category}/insights`,
      }),
    }),
  }),
})

export const {
  useGetCategoriesQuery,
  useGetCategoriesExperienceQuery,
  useGetCategoryInsightsQuery,
} = categoryApi
