import React from 'react'

import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router'

import styled from 'styled-components'

import { baseColors, Text } from '@fairhq/common'
import { ReportsModularSidebar } from 'features/reportsModularSidebar/ReportsModularSidebar'
import { Layout } from 'layout/Layout'

import { useGetCategoriesExperienceQuery } from 'store/category/categoryApi'

import { useGetSurveyWindowArchiveComparisonQuery } from 'store/surveyWindow/surveyWindowApi'

import { Categories } from './Categories'

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ReportCategories = () => {
  const { category } = useParams<{ category: string }>()
  const { t } = useTranslation()

  const { data: surveyWindows } = useGetSurveyWindowArchiveComparisonQuery()

  const { data, isFetching } = useGetCategoriesExperienceQuery(
    { category },
    { skip: !category }
  )

  return (
    <Layout
      contentSx={{ display: 'flex', flexDirection: 'column', gap: '60px' }}
      sidebar={<ReportsModularSidebar />}
      dark
    >
      <Header>
        <Text
          element="h1"
          type="heading"
          size="2xl"
          fontWeight={400}
          color={baseColors.coolGrey900}
        >
          {t(`category.${category}`)}
        </Text>
      </Header>
      <Wrapper>
        <Container>
          <Text element="h2" type="body" size="xl" fontWeight={700}>
            {t('category.risk.title')}
          </Text>
          <Text element="p" type="body" size="md" fontWeight={400}>
            {t('category.risk.description')}
          </Text>
        </Container>

        <Categories
          loading={isFetching}
          previousSurveyWindowId={surveyWindows?.[1]?.id}
          categoryExperiences={data}
        />
      </Wrapper>
    </Layout>
  )
}
