import React, { FunctionComponent } from 'react'

import styled from 'styled-components'

import { Text } from '@fairhq/common'

import { DifferenceTag } from 'components/DifferenceTag'
import { SubCategoryExperience } from 'store/category/types'
import { getScoreColor } from 'utils/getScoreColor'

import { HappyFace } from './assets/HappyFace'
import { SadFace } from './assets/SadFace'
import { useExperienceBar } from './hooks/useExperienceBar'

const BaseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
const Wrapper = styled(BaseWrapper)`
  gap: 24px;
  min-width: 234px;
`

const BarWrapper = styled(BaseWrapper)`
  gap: 2px;
  justify-content: center;
`

const Bar = styled.div<{ backgroundImage: string }>`
  width: 100px;
  height: 12px;
  border-radius: 8px;
  background-image: ${({ backgroundImage }) => backgroundImage};
  background-repeat: no-repeat;
`

const Face = styled.div`
  width: 24px;
  display: flex;
  align-items: center;
`

const FaceLeft = styled(Face)`
  justify-content: end;
`

const FaceRight = styled(Face)`
  justify-content: start;
`

const TagContainer = styled.div`
  width: 78px;
`

const EnpsWrapper = styled.div`
  width: 38px;
  text-align: center;
`

interface ExperienceBarProps {
  categoryExperience: SubCategoryExperience
  previousExperience?: SubCategoryExperience
}

export const ExperienceBar: FunctionComponent<ExperienceBarProps> = ({
  categoryExperience,
  previousExperience,
}) => {
  const { sadSize, happySize, backgroundImage, colors, enps, enpsDiff } =
    useExperienceBar({
      categoryExperience,
      previousExperience,
    })

  return (
    <Wrapper>
      <BarWrapper>
        <FaceLeft>
          <SadFace stroke={colors.red} width={sadSize} height={sadSize} />
        </FaceLeft>
        <Bar backgroundImage={backgroundImage} />
        <FaceRight>
          <HappyFace
            stroke={colors.green}
            width={happySize}
            height={happySize}
          />
        </FaceRight>
      </BarWrapper>
      {enps && (
        <>
          <EnpsWrapper>
            <Text
              element="p"
              type="body"
              size="lg"
              fontWeight={600}
              color={getScoreColor(Number(enps) * 0.1)}
            >
              {enps}
            </Text>
          </EnpsWrapper>
          {previousExperience && (
            <TagContainer>
              {enpsDiff ? <DifferenceTag value={Number(enpsDiff)} /> : null}
            </TagContainer>
          )}
        </>
      )}
    </Wrapper>
  )
}
